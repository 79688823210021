import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Typography } from "@/components/typography/typography";
import { Icon, IconSize } from "@/design-system/atoms/icons";
import { ValueUnit } from "@/design-system/atoms/unit";
import type { PropsWithTheme } from "@/theme";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";

import type { AttributeProps, AttributeSize } from "./types";
import { AttributeSizeOptions } from "./types";

export const Attributes = styled.div<PropsWithTheme & AttributeSize>`
	display: grid;
	${({ theme: { mq }, size }) => css`
		grid-template-columns: ${size === AttributeSizeOptions.regular
			? "1fr 1fr"
			: "repeat(3, 1fr)"};
		grid-auto-rows: min-content;
		grid-gap: ${size === AttributeSizeOptions.regular
			? "var(--spacing-xxs) var(--spacing-m)"
			: "var(--spacing-m)"};
		margin: ${size === AttributeSizeOptions.regular ? "0 var(--spacing-xs)" : 0};
		@media ${mq.l} {
			grid-gap: ${size === AttributeSizeOptions.regular
				? "var(--spacing-xxs) var(--spacing-m)"
				: "var(--spacing-m)"};
		}
	`};
`;

export const StyledAttribute = styled.span<PropsWithTheme & AttributeSize>`
	display: flex;
	${({ theme: { mq }, size }) => css`
		align-items: ${size === AttributeSizeOptions.regular ? "initial" : "center"};
		flex-direction: ${size === AttributeSizeOptions.regular ? "row" : "column"};
		@media ${mq.l} {
			flex-direction: row;
		}
	`};
`;

export const StyledAttributeText = styled.p<PropsWithTheme & AttributeSize>`
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-end;
	line-height: 0.8em;
	${({ theme: { mq }, size }) => css`
		align-items: ${size === AttributeSizeOptions.regular ? "initial" : "center"};
		margin: 0 0 0 ${size === AttributeSizeOptions.regular ? "var(--spacing-xxxs)" : 0};
		@media ${mq.l} {
			align-items: flex-start;
			padding-left: ${size === AttributeSizeOptions.regular ? 0 : "var(--spacing-xxxs)"};
		}
	`};
`;

export const StyledIconWrapper = styled.div<PropsWithTheme & AttributeSize>`
	${({ theme: { mq, palette }, size }) => css`
		margin-bottom: ${size === AttributeSizeOptions.regular ? 0 : "5px"};
		color: ${palette.freeze[500]};
		@media ${mq.l} {
			margin-bottom: 0;
		}
	`};
`;

export const StyledTitle = styled(Typography)<PropsWithTheme>`
	line-height: 1;
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[500]};
	`};
`;

const StyledValue = styled(Typography)<PropsWithTheme & AttributeSize>`
	${({ theme: { mq, palette }, size }) => css`
		color: ${palette.freeze[1000]};
		font-weight: ${FONT_WEIGHT.medium};
		font-size: ${size === AttributeSizeOptions.regular ? "14px" : "18px"};
		line-height: ${size === AttributeSizeOptions.regular ? "1.3em" : "1.2em"};
		margin-bottom: ${size === AttributeSizeOptions.regular ? 0 : "5px"};
		@media ${mq.l} {
			margin-bottom: 4px;
		}
	`};
`;

const iconSize = {
	[AttributeSizeOptions.regular]: IconSize.m,
	[AttributeSizeOptions.largeResponsive]: IconSize.l,
};

export const Attribute: React.FC<AttributeProps> = ({
	icon,
	prefix,
	size = AttributeSizeOptions.regular,
	title,
	unit,
	value,
}) => {
	return (
		<StyledAttribute size={size}>
			<StyledIconWrapper size={size}>
				<Icon icon={icon} size={iconSize[size]} />
			</StyledIconWrapper>
			<StyledAttributeText size={size}>
				<StyledTitle
					tight
					component="span"
					variant={TypographyVariant.bodySM}
					weight={FONT_WEIGHT.light}
				>
					{title}
				</StyledTitle>
				<StyledValue tight component="span" size={size}>
					{prefix && `${prefix} `}
					<ValueUnit value={value} unit={unit} />
				</StyledValue>
			</StyledAttributeText>
		</StyledAttribute>
	);
};
