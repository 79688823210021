export interface AreaUnitProps {
	value: string | number;
	unit?: UnitType;
}

export enum UnitType {
	m = "m",
	ft = "ft",
	kwh = "kwh",
	number = "number",
}
