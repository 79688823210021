import type { IconName } from "@/design-system/atoms/icons";
import type { UnitType } from "@/design-system/atoms/unit/types";

export interface AttributeSize {
	size: AttributeSizeOptions;
}

export enum AttributeSizeOptions {
	regular = "regular",
	largeResponsive = "largeResponsive",
}

export interface AttributeProps {
	icon: IconName;
	prefix?: string;
	size?: AttributeSizeOptions;
	title: string;
	unit?: UnitType;
	value: string | number;
	className?: string;
}
