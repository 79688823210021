import React from "react";

import { useRouter } from "next/router";

import { formatNumber } from "@/utils/number";

import type { AreaUnitProps} from "./types";
import { UnitType } from "./types";

export const Unit: React.FC<{ unit: UnitType }> = ({ unit }) => {
	switch (unit) {
		case UnitType.m:
			return (
				<>
					m<sup>2</sup>
				</>
			);
		case UnitType.ft:
			return (
				<>
					ft<sup>2</sup>
				</>
			);
		case UnitType.kwh:
			return (
				<>
					kWh/(m<sup>2</sup>*a)
				</>
			);
		default:
			return null;
	}
};

export const ValueUnit: React.FC<AreaUnitProps> = ({ value, unit }) => {
	const { locale } = useRouter();

	if (!unit) {
		return <span>{`${value} `}</span>;
	}

	return (
		<span>
			{`${formatNumber(value, locale)} `}
			<Unit unit={unit} />
		</span>
	);
};
